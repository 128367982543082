import React, { useContext, useState, useEffect } from "react";
import Meta from "../components/Meta/Meta";
import { DataContext } from "../contexts/DataContext";
import { toast } from "react-toastify";
import Hero3BC from "../components/Hero3BC/Hero3BC";
import Spacer from "../components/Spacer/Spacer";
import Faq from "../components/Faq/Faq";
import { Colors } from "../commons/Theme";
import Reviews from "../components/Reviews/Reviews";
import SectionTwoCol from "../components/SectionTwoCol/SectionTwoCol";
import NumbersToRevers from "../components/NumbersToRevers/NumbersToRevers";
import SectionHeader from "../components/SectionHeader/SectionHeader";
import BusinessIconText from "../components/BusinessIconText/BusinessIconText";
import InfoBox from "../components/InfoBox/InfoBox";
import ImageContainer from "../components/ImageContainer/ImageContainer";
import { Button } from "@3beehivetech/components";
import useNavigate from "../hooks/useNavigate";

const adottaUnAlbero = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const {
    pageLabels,
    currentLanguageSuffix,
    pageImages,
    i18nLink,
    labels,
  } = useContext(DataContext);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  toast.configure();

  return(
    <div className={scrollPosition > 2500 ? "black-background" : "white-background"}  style="transition: background-color 2s ease;">
      <Meta />
      <Hero3BC
        title = {pageLabels.hero.title}
        image = {pageLabels.hero.image}
        paragraph = {pageLabels.hero.paragraph}
        buttonText = {pageLabels.hero.cta}
        to = "#trees"
        titleBackground = "transparent"
        buttonVariant="orange"
        noNewsletter={true}
        lang={currentLanguageSuffix}
        allImages = {pageImages}
      />
      <Spacer size={10}/>
      <h2 style="text-align: center; font-size: 40px; color: #333333;">Le api al centro</h2>
      <ImageContainer
        image={"/2020/images/apechipstyle2.jpg"}
      />
      <h2 style="text-align: center; font-size: 40px; color: #333333;">Un mondo con le api</h2>
      <Spacer size={10}/>
      <div style="text-align: center; align-self: center;">
        <Button
          variant="green"
          onClick={useNavigate("/trees")}
        >{labels.Go_Trees}</Button>
      </div>
      <Spacer size={20}/>
      <div className="color-green-primary-background">
        <Spacer size={4}/>
        <SectionHeader title={pageLabels.addons.title} padding={"0 2rem"}/>
        <BusinessIconText items={pageLabels.addons.items}/>
      </div>
      <Spacer size={10}/>
      <SectionTwoCol
        id="oasi"
        titleDefault={true}
        title={pageLabels.oasi.title}
        subtitle1={pageLabels.oasi.subtitle}
        cta={pageLabels.oasi.button}
        ctaVariant="green"
        to="#trees"
        image={pageLabels.oasi.image}
      />
      <Spacer size={10}/>
      <InfoBox
        variant="green"
        icon={pageLabels.forest.image}
        ctaLabel={pageLabels.forest.ctaLabel}
        title={pageLabels.forest.title}
        items={pageLabels.forest.items}
      />
      <Spacer size={10}/>
      <SectionTwoCol
        id="grower"
        titleDefault={true}
        title={pageLabels.grower.title}
        subtitle1={pageLabels.grower.subtitle}
        cta={pageLabels.grower.button}
        ctaVariant="green"
        topImage={true}
        to={i18nLink("/terreno-biodiversita/")}
        image={pageLabels.grower.image}
      />
      <Spacer size={10}/>
      <section>
        <SectionHeader title={pageLabels.numbers.title} padding={"0 2rem"}/>
        <Spacer size={4}/>
        <NumbersToRevers items={pageLabels.numbers.items}/>
      </section>
      <Spacer size={10}/>
      <Reviews title={pageLabels.reviews.title} highlighted={true} items={pageLabels.reviews.items}/>
      <Spacer size={10}/>
      <Faq
        variant={Colors.greenlight}
        data={pageLabels.faq.dataFile.data}
      />
    </div>
  );
};

export default adottaUnAlbero;
