import React from "react";
import { 
  StyledImage,
  StyledImageContainer,
} from "./style";

const ImageContainer = ({
  id,
  image,
}) => {
  return (
    <StyledImageContainer id={id}>
      <div>
        <StyledImage
          src={image}
          loading="lazy"
        />
      </div>
    </StyledImageContainer>
  );
};

export default ImageContainer;
