import styled from "styled-components";
import {
  BP,
} from "../../commons/Theme";

export const StyledImageContainer = styled.div`
  width: 100%;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

export const StyledImage = styled.img`
  max-height: 250px;
  @media (${BP.tablet}) {
    max-height: 524px;
  }
`;
